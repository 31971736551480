import React from "react";
import Diamondeducation from "../components/Diamondeducation"
import {graphql} from "gatsby";
import Layout from "../components/Layout";
import {connect} from "react-redux";
import SEO from "../components/SEO";

const Wholesale = (props) => {
    return (
        <Layout>
            <SEO {...(props?.data?.seo?.frontmatter || {})}/>
            <Diamondeducation {...props} fields={props?.data}/>
        </Layout>
    )
};
const mapStateToProps = (props) => {
    return props;
};
export default connect(mapStateToProps, null)(Wholesale);

export const query = graphql`
fragment WholesaleFragment on MarkdownRemark {
     frontmatter {
      title
      description
      img{
        publicURL
      }
    }
  }
  query WholesalePageQuery {
    seo: markdownRemark(frontmatter: {templateKey: {eq: "wholesale-seo"}}) {
        frontmatter {
            title
            description
        }
  }
  enWholesale: markdownRemark(frontmatter: {templateKey: {eq: "en-wholesale"}}) {
    ...WholesaleFragment
  }
    deWholesale: markdownRemark(frontmatter: {templateKey: {eq: "de-wholesale"}}) {
    ...WholesaleFragment
  }
    esWholesale: markdownRemark(frontmatter: {templateKey: {eq: "es-wholesale"}}) {
    ...WholesaleFragment
  }
    frWholesale: markdownRemark(frontmatter: {templateKey: {eq: "en-wholesale"}}) {
    ...WholesaleFragment
  }
    heWholesale: markdownRemark(frontmatter: {templateKey: {eq: "he-wholesale"}}) {
    ...WholesaleFragment
  }
    itWholesale: markdownRemark(frontmatter: {templateKey: {eq: "it-wholesale"}}) {
    ...WholesaleFragment
  }
    jaWholesale: markdownRemark(frontmatter: {templateKey: {eq: "ja-wholesale"}}) {
    ...WholesaleFragment
  }
    zhWholesale: markdownRemark(frontmatter: {templateKey: {eq: "zh-wholesale"}}) {
    ...WholesaleFragment
  }
 }
`